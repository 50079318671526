import type { InterceptorProps } from "./_model";
import { AXIOS, KEYS, log } from "./const";

export function attachRequestInterceptor(props: InterceptorProps) {
  props.instance.interceptors.request.use(
    async (config) => {
      if (!config) config = {} as any;
      if (!config.headers) config.headers = {} as any;
      // @ts-ignore
      config = props.options?.interceptors?.processRequests?.({ req: config }) ?? config;
      const internal_config = props.getInternalConfig();
      const state = props.getConfigState(config);
      if (!state.auth.is_auth || state.auth.is_internal_request) {
        return config;
      }

      let mode: "withCreds" | "bearer" | "clear" = undefined;
      if (internal_config.httpOnlyCookieTempSession) {
        mode = "bearer";
      } else {
        if (props.options.auth?.mode === "http-only-cookie") {
          mode = "withCreds";
          if (state.auth.endpoints.is_token && props.internalInvokers.tokenExist()) {
            try {
              //? request is skipped internally and revalidating is handled on the response side
              await props.instance.get(props.options.auth.endpoints.getTokenRemove?.url, {
                ...config,
                withCredentials: true,
                [KEYS.CONFIG.INTERNAL_REQUEST]: true,
              });
            } catch (e) {
              // throw new Error("AxiosInstance: couldn't refresh token, logging out");
            }
            props.internalInvokers.clearToken({ skipUpdate: true });
          } else if (!state.auth.endpoints.is_auth_endpoint) {
            if (internal_config.first_auth_request) {
              // first auth call and it hasn't resolved yet, intercept an call the check endpoint
              try {
                //? request is skipped internally and revalidating is handled on the response side
                await props.instance.get(props.options.auth.endpoints.getTokenRevalidate?.url, {
                  withCredentials: true,
                  ...config,
                });
              } catch (e) {
                throw new Error("AxiosInstance: couldn't refresh token, logging out");
              }
            }
          }
        } else if (props.options.auth?.mode === "localstorage") {
          mode = "bearer";
          if (state.auth.endpoints.is_token && props.internalInvokers.tokenExist()) {
            props.internalInvokers.clearToken({ skipUpdate: true });
          } else if (!state.auth.endpoints.is_auth_endpoint) {
            // if (!internal_config.initizlized) {
            //   props.initialize(config);
            // }
            let should_refresh = false;
            const token_response = props.getTokenResponse();
            if (!token_response) {
              // special case
              state.auth.is_auth = true;
              state.auth.endpoints.is_auth_endpoint = true;
              state.auth.endpoints.is_token_revalidate = true;
              props.revalidateAuthedCall({ err: "no token response to use for token refresh", state });
              throw new Error("AxiosInstance: token doesn't exist, detected future unauthorized request");
            } else if (internal_config.manualTokenExpire) {
              const now = Date.now();
              const date = internal_config.manualTokenExpire;
              should_refresh = now >= date.getTime();
            } else {
              const decoded = props.getTokenDecoded();
              if (decoded) {
                should_refresh = Date.now() >= decoded.exp * 1000;
              }
            }
            if (should_refresh) {
              log("detected expired token before next auth call, refreshing!");

              const refresh = props.options.auth?.events?.getTokenRefresh({ res: token_response });
              try {
                //? request is skipped internally and revalidating is handled on the response side
                await props.instance.post(props.options.auth.endpoints.postTokenRevalidate?.url, refresh, {
                  ...config,
                  headers: {
                    Authorization: `Bearer ${props.options?.auth?.events.getToken?.({ res: token_response })}`,
                  },
                });
              } catch (e) {
                throw new Error("AxiosInstance: couldn't refresh token, logging out");
              }
            }
          }
        }
      }
      //
      //
      if (mode === "withCreds") {
        config.withCredentials = true;
      } else {
        const token_response = props.getTokenResponse();
        if (token_response) {
          const token = props.options?.auth?.events?.getToken({ res: token_response });
          config.headers.Authorization = `Bearer ${token}`;
          // config.headers["x-access-token"] = token; // for Node.js Express back-end
        }
      }
      if (internal_config.first_auth_request) {
        props.updateInternalConfig({ first_auth_request: false });
      }
      return config;
    },
    (err) => {
      // @ts-ignore
      err = props.options?.interceptors?.processRequestsErrors?.({ err }) ?? err;
      return Promise.reject(err);
    }
  );
}
